import { MultiPromotion } from '@loveholidays/design-system';
import React, { memo } from 'react';

import { PromoCampaign } from '@AuroraTypes';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

export const MultiPromotionComponent: React.FC<{ campaigns: PromoCampaign[] }> = memo(
  ({ campaigns }) => {
    useFeatureFlag('AACachedLandingPagesHomepage');

    return (
      <MultiPromotion
        campaigns={campaigns}
        TileClickTracking={WithClickTracking}
        imageSettings={{ quality: 70, dpr: 0.75 }}
      />
    );
  },
);
